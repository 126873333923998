<template>
  <div>
    <form-create v-model="where" :components="[
              {name:'form-item',props:{prop:'teach_name',label:'老师名称'}},
          ]" inline :labelWidth="'70px'">
      <el-form-item class="operate" slot="after">
        <el-button type="primary" size="small" @click="$refs.table.reset(where)">搜索</el-button>
        <el-button type="primary" size="small" @click="$refs.table.reset(where = {})">重置</el-button>
      </el-form-item>
    </form-create>
    <div class="operate" style="margin-bottom: 15px">
      <el-button-group>
        <el-button type="primary" :disabled="!$isPowers('courseTeachCreate')" size="small" @click="$refs.dialog.visibled((dialogData = {title:'新增指导老师',data:{}}) && true)">新增指导老师</el-button>
      </el-button-group>
    </div>
    <com-table :columns="[
                    {prop:'headimg',label:'头像',width:'80',slot:'avatar'},
                    {prop:'nickname',label:'老师姓名',minWidth:'220',slot:'teachName'},
                    {prop:'phone',label:'手机号',width:'160'},
                    {prop:'email',label:'教师邮箱',width:'200'},
                    {prop:'create_date',label:'添加时间',width:'160' , align:'center'},
                    {fixed:'right',label:'操作',width:'240' , slot:'action' , align:'center'},
                ]"
               :data="{url:'/xapi/course.course/teachList'}"
               :where="where"
               ref="table"
    >
      <template slot="teachName" slot-scope="{row}">
        {{row.nickname.length>0?row.nickname:row.realname}}
      </template>
      <template slot="action" slot-scope="{row}">
        <el-button-group>
          <el-button type="primary"
                     size="mini"
                     :disabled="!$isPowers('courseTeachDel')"
                     v-if="row.id+''!==courseInfo.user_id+''&&row.id+''!==courseInfo.lecturer_id+''"
                     @click="$handleConfirm('你确定将指导老师['+row.realname+']从该课程中删除吗？','/xapi/course.course/teachDel',{teach_id:row.id, ...$route.params} , ()=>{$refs.table.reload();},{title:'删除'})"
          >删除</el-button>
        </el-button-group>
      </template>
    </com-table>
    <diy-dialog ref="dialog" :title="'新增指导老师'" width="450px" center>
      <form-create ref="dialogForm" v-model="dialogData.data" :label-width="'100px'" :components="[
              // {name:'form-item',props:{prop:'teach_id',label:'选择老师',placeholder:'请选择老师', type:'select' , multiple:true,data:{ url:'/xapi/user/teachList', labelOptions:{value:'id',label:'showname'} }}},
              {name:'form-item',props:{prop:'teach_id',label:'选择老师',placeholder:'请选择老师', type:'select' , multiple:true,data:{ url:'/xapi/user.user/teachList', labelOptions:{value:'id',label:'showname'} }}},
          ]">
      </form-create>
      <div slot="footer" style="display: flex;justify-content: space-between;padding: 0 80px;">
        <el-button type="primary" @click="$refs.dialog.visibled(false)">取 消</el-button>
        <el-button type="primary" @click="handleCommand('save')">确 定</el-button>
      </div>
    </diy-dialog>
  </div>
</template>

<script>
import DiyDialog from "@/components/com/diyDialog";
export default {
  components: {DiyDialog},
  props:['course_id'],
  data(){
    return {
      dialogData:{
        title:'',
        data:{},
      },
      where:{},
      courseInfo:{},
    };
  },
  methods:{
    routeUpdate(){
      this.getDetail();
    },
    handleCommand(command){
      if(command === 'save'){
        this.$refs.dialogForm && this.$refs.dialogForm.validate((formData , valid)=>{
          if(valid){
            this.$http.post('/xapi/course.course/teachSave' ,{...formData , course_id:this.course_id}).then((data)=>{
              this.$handleMessage(data.msg || '操作成功！' , 'success' , ()=>{
                this.$refs.table.reload();
                this.$refs.dialog.visibled(false);
              })
            })
          }
        })
      }
    },
    getDetail(){
      if(this.course_id > 0){
        this.$http.post('/xapi/course.course/detail' ,{id:this.course_id}).then(({data,isOnlyField})=>{
          this.courseInfo = data;
        });
      }
    },
  }
}
</script>

<style scoped>

</style>