var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form-create',{attrs:{"components":[
            {name:'form-item',props:{prop:'teach_name',label:'老师名称'}},
        ],"inline":"","labelWidth":'70px'},model:{value:(_vm.where),callback:function ($$v) {_vm.where=$$v},expression:"where"}},[_c('el-form-item',{staticClass:"operate",attrs:{"slot":"after"},slot:"after"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.$refs.table.reset(_vm.where)}}},[_vm._v("搜索")]),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.$refs.table.reset(_vm.where = {})}}},[_vm._v("重置")])],1)],1),_c('div',{staticClass:"operate",staticStyle:{"margin-bottom":"15px"}},[_c('el-button-group',[_c('el-button',{attrs:{"type":"primary","disabled":!_vm.$isPowers('courseTeachCreate'),"size":"small"},on:{"click":function($event){_vm.$refs.dialog.visibled((_vm.dialogData = {title:'新增指导老师',data:{}}) && true)}}},[_vm._v("新增指导老师")])],1)],1),_c('com-table',{ref:"table",attrs:{"columns":[
                  {prop:'headimg',label:'头像',width:'80',slot:'avatar'},
                  {prop:'nickname',label:'老师姓名',minWidth:'220',slot:'teachName'},
                  {prop:'phone',label:'手机号',width:'160'},
                  {prop:'email',label:'教师邮箱',width:'200'},
                  {prop:'create_date',label:'添加时间',width:'160' , align:'center'},
                  {fixed:'right',label:'操作',width:'240' , slot:'action' , align:'center'},
              ],"data":{url:'/xapi/course.course/teachList'},"where":_vm.where},scopedSlots:_vm._u([{key:"teachName",fn:function({row}){return [_vm._v(" "+_vm._s(row.nickname.length>0?row.nickname:row.realname)+" ")]}},{key:"action",fn:function({row}){return [_c('el-button-group',[(row.id+''!==_vm.courseInfo.user_id+''&&row.id+''!==_vm.courseInfo.lecturer_id+'')?_c('el-button',{attrs:{"type":"primary","size":"mini","disabled":!_vm.$isPowers('courseTeachDel')},on:{"click":function($event){_vm.$handleConfirm('你确定将指导老师['+row.realname+']从该课程中删除吗？','/xapi/course.course/teachDel',{teach_id:row.id, ..._vm.$route.params} , ()=>{_vm.$refs.table.reload();},{title:'删除'})}}},[_vm._v("删除")]):_vm._e()],1)]}}])}),_c('diy-dialog',{ref:"dialog",attrs:{"title":'新增指导老师',"width":"450px","center":""}},[_c('form-create',{ref:"dialogForm",attrs:{"label-width":'100px',"components":[
            // {name:'form-item',props:{prop:'teach_id',label:'选择老师',placeholder:'请选择老师', type:'select' , multiple:true,data:{ url:'/xapi/user/teachList', labelOptions:{value:'id',label:'showname'} }}},
            {name:'form-item',props:{prop:'teach_id',label:'选择老师',placeholder:'请选择老师', type:'select' , multiple:true,data:{ url:'/xapi/user.user/teachList', labelOptions:{value:'id',label:'showname'} }}},
        ]},model:{value:(_vm.dialogData.data),callback:function ($$v) {_vm.$set(_vm.dialogData, "data", $$v)},expression:"dialogData.data"}}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","padding":"0 80px"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$refs.dialog.visibled(false)}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleCommand('save')}}},[_vm._v("确 定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }